import React from "react"
import styled from "styled-components"
import {
  BottomFloeWrapper,
  InnerFloeContainer,
  Slider,
  TopFloeWrapper,
  TopFloeWrapperStyle,
} from "@igloonet-web/shared-ui"

import Ares from "../../images/old-employees/ares.jpg"
import Jedlickova from "../../images/old-employees/jedlickova.jpg"
import Jindra from "../../images/old-employees/jindra.jpg"
import Jitka from "../../images/old-employees/jitka.jpg"
import Michalg from "../../images/old-employees/michalg.jpg"
import Pesout from "../../images/old-employees/pesout.png"
import Tuha from "../../images/old-employees/tuha.png"
import Zuzana from "../../images/old-employees/zuzana.jpg"
import Onsmak from "../../images/old-employees/onsmak.png"

const ReferenceContainer = styled.div`
  background: ${(props) => props.theme.color.gray200};
`

const referenceList = [
  {
    name: "Jindrich Faborsky",
    imageSrc: Jindra,
    linkedin: "https://www.linkedin.com/in/faborsky/",
    position: "Marketingový manažer 2009 - 2013",
    text: `V\u00A0igloonetu jsem začínal s\u00A0online marketingem, takže jemu i\u00A0jeho kolektivu dlužím opravdu hodně. Kdybych aktuálně nepodnikal a\u00A0chtěl se vrátit k\u00A0agenturní práci, určitě bych nepřemýšlel nad jinou firmou.`,
  },
  {
    name: "Martin Pešout",
    imageSrc: Pesout,
    linkedin: "https://www.linkedin.com/in/martinpesout/",
    position: "Frontend vývojář 2007 - 2014",
    text: `V\u00A0igloonetu jsem strávil úžasných 7\u00A0let. Ze všeho nejvíce musím ocenit firemní atmosféru. Kdo to nezažil, nepochopí :) Navíc firma vždy naslouchala tomu, co bych rád dělal. Bez igloo bych se nikdy nestal takovým frontend vývojařem, jakým jsem nyní. Díky!`,
  },
  {
    name: "Barbora Jedličková",
    imageSrc: Jedlickova,
    linkedin: "",
    position: "Marketingová specialistka 2015",
    text: `V\u00A0igloo jsem se posunula dál díky praktickým radám a\u00A0přístupu kolegů z\u00A0Facebook týmu. Skvělá atmosféra a\u00A0úžasný kolektiv mi dodaly pocit, že chodit do práce může být zábava. igloonet nasadil laťku proklatě vysoko!`,
  },
  {
    name: "Martina Tuhá",
    imageSrc: Tuha,
    linkedin: "",
    position: "Stážistka 2014",
    text: `Díky stáži v\u00A0igloonetu jsem měla možnost přičichnout k\u00A0základům Adwords, Skliku a\u00A0SEO. Zkusila jsem si tvorbu různých formátů reklam, strategie, ale nejvíc mě strhl Facebook marketing, kterému se věnuji dodnes. V\u00A0přátelském týmu skvělých lidí, kteří mi věnovali svůj čas a\u00A0zkušenosti, jsem poznala kouzlo online marketingu. Dík!`,
  },
  {
    name: "Jitka Kolaříková",
    imageSrc: Jitka,
    linkedin: "",
    position: "Frontend vývojářka 2016",
    text: `Díky stáži v\u00A0igloo jsem se za měsíc naučila vytvořit super webovou stránku, poznala spoustu smartass tučňáků, několikrát se přejedla pizzou a\u00A0měla kofeinový šok. Díky za tuto příležitost, nezapomenutelná!`,
  },
  {
    name: "Michal Gritzbach",
    imageSrc: Michalg,
    linkedin: "https://www.linkedin.com/in/michalgritzbach/",
    position: "Backend vývojář 2014 - 2016",
    text: `Strávil jsem v\u00A0igloonetu přes dva roky a\u00A0už jsem potřeboval změnu. Přestože občas bylo i\u00A0dusno, zvlášť když jsme se v\u00A0roce 2015 prakticky zdvojnásobili, tučňáci mi budou ještě dlouho chybět. Atmosféra a\u00A0kafe jsou tu prostě jedinečné.`,
  },
  {
    name: "Marek Hulán",
    imageSrc: Ares,
    linkedin: "https://www.linkedin.com/in/marek-hul%C3%A1n-0307097/",
    position: "Ruby on Rails vývojář 2009 - 2012",
    text: `igloonet není typický start-up, ale rozhodně nastartuje vaši kariéru. Doporučuji každému, kdo má hlad po zkušenostech.`,
  },
  {
    name: "Zuzana Šumlanská",
    imageSrc: Zuzana,
    linkedin:
      "https://www.linkedin.com/in/zuzana-%C5%A1umlansk%C3%A1-197353a1/",
    position: "Frontend vývojářka 2016",
    text: `Poznala jsem spoustu skvělých lidí, se kterými se chci stýkat dál. Z profesního hlediska jsem si potvrdila, že frontend chci rozhodně dělat. Když se ohlédnu, vidím za sebou kus práce a\u00A0progres, o\u00A0jakém se mi ani nesnilo.`,
  },
  {
    name: "David Czernín",
    imageSrc: Onsmak,
    linkedin: "",
    position: "Frontend vývojář",
    text: `Necelé dva roky v\u00A0igloo jsou a\u00A0budou pro mne srdcovka. Poznal jsem v\u00A0něm spoustu skvělých tučňáků, vypil snad desítky litrů kávy a\u00A0získal mnoho pracovních zkušeností. Avšak igloo tvoří hlavně atmosféra a\u00A0lidé, za kterými jsem se rád vracel.`,
  },
]

const Reference: React.FC = () => (
  <BottomFloeWrapper>
    <TopFloeWrapper style={TopFloeWrapperStyle.EmptyFloe}>
      <InnerFloeContainer>
        <ReferenceContainer id="employes">
          <Slider
            title="Tučňákem jednou, tučňákem napořád"
            data={referenceList}
            longText
          />
        </ReferenceContainer>
      </InnerFloeContainer>
    </TopFloeWrapper>
  </BottomFloeWrapper>
)

export default Reference
