import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import {
  Gallery,
  ContactFormJobs,
  Banner,
  BannerStyle,
} from "@igloonet-web/shared-ui"
import Accordion from "../components/accordion"
import Reference from "../components/jobs/reference"
import { Layout, JobsContact } from "../components"
import OgImage from "../images/position/og-image-jobs.png"
import { Helmet } from "react-helmet"
import { BENEFITS } from "../model/benefits"
import BenefitPenguin from "../images/jobs/benefit_penguin.svg"

const JobsPage: React.FC = () => {
  const data = useStaticQuery(graphql`
    query InstagramQuery {
      allInstagramContent(limit: 14) {
        edges {
          node {
            caption
            timestamp(formatString: "DD.MM.YYYY")
            localImage {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
  `)

  const instaPhoto = data.allInstagramContent.edges.map((edge) => ({
    imageSrc: edge.node.localImage.childImageSharp.original.src,
    text: edge.node.caption,
    time: edge.node.timestamp,
  }))
  return (
    <Layout>
      <Helmet>
        <title>
          Každý strávíme ⅓ života v práci. Vybírej chytře, s kým ji prožiješ |
          igloonet
        </title>
        <meta
          name="description"
          content="Chceš pracovat v\u00A0kolektivu, kde bude tvé slovo slyšet?
          Staň se tučňákem a pracuj v igloo. #nejsmezledu"
        />
        <meta property="og:title" content="Pracuj v igloo!" />
        <meta property="og:image" content={OgImage} />
      </Helmet>

      <Banner
        style={BannerStyle.Jobs}
        orangeHeading={`Třetinu života<br/>strávíme v&nbsp;práci`}
        blackHeading={`vybírej chytře,<br/>s&nbsp;kým ji prožiješ`}
        text="Pracuj v&nbsp;kolektivu, kde bude tvé slovo slyšet. Staň se tučňákem."
      />

      <JobsContact />

      <Reference />

      <Accordion
        mainTitle="Nabízíme jedinečný balíček benefitů"
        data={BENEFITS}
        mainImage={BenefitPenguin}
      />

      <Gallery data={instaPhoto} />

      <div id="formular">
        <ContactFormJobs
          heading="Stačí jeden krok a už se nás nezbavíš"
          lang="csTykani"
        />
      </div>
    </Layout>
  )
}

export default JobsPage
